<template>
  <div class="news">
    <div class="news__wrapper">
      <div
        class="news__header-background"
        :style="{
          backgroundImage: `url(${specificNew.header_image})`,
        }"
      ></div>
      <div class="news__main-container">
        <div class="news__details-box">
          <div class="news__details-box_tags">
            <Tag
              class="tag--public"
              v-for="tag in specificNew.tags"
              :key="tag.id"
              :value="tag.title"
              @click="
                $router.push({
                  path: '/content',
                  query: { tag_id: tag.id },
                })
              "
            />
          </div>
          <h2 class="news__details-box_title">
            {{ specificNew.title }}
          </h2>
          <span
            v-if="specificNew.date_published && specificNew.author"
            class="news__details-box_data"
            >{{ specificNew.date_published }} | by {{ specificNew.author }}</span
          >
          <div
            class="news__details-box_artical collect-editor collect-editor--white"
            v-html="specificNew.details"
          ></div>
          <BaseButton
            v-if="specificNew.button_text && specificNew.button_url"
            class="button button--xs button--white"
            :text="specificNew.button_text"
            @click="goToLink(specificNew.button_url)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import Tag from "@/components/Tag.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  components: { Tag, BaseButton },

  data() {
    return {};
  },
  head: {
    title: function () {
      return {
        inner: this.specificNew.title ?? "News browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: `${this.specificNew.title ?? "News browngrotta arts"} - ${
            this.specificNew.description ?? "Description News browngrotta arts"
          }`,
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: this.specificNew.title ?? "News browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content:
            this.specificNew.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: this.specificNew.title ?? "News browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content:
            this.specificNew.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: this.specificNew.title ?? "News browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: this.specificNew.description ?? "Description News browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: this.specificNew.title ?? "News browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: this.specificNew.description ?? "Description News browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content:
            this.specificNew.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: this.specificNew.title ?? "News browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  computed: {
    ...mapGetters("publicapi/news", { specificNew: "getSpecificNew" }),
  },

  async created() {
    await this.apiGetSpecificNew(this.$route.params.slug);
    this.$emit("updateHead");
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Media",
        to: "/media",
        clickable: true,
      },
      {
        title: "In the news",
        to: "/media/in-the-news",
        clickable: true,
      },
      {
        title: `${this.specificNew.title}`,
        to: `/media/in-the-news/${this.$route.params.slug}`,
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);

    this.setShareData({
      title: this.specificNew.title,
      description: this.specificNew.details,
      image: this.specificNew.header_image,
    });
  },

  methods: {
    ...mapActions("publicapi/news", ["apiGetSpecificNew"]),
    ...mapMutations("publicapi/share", ["setShareData"]),
    ...mapMutations(["setBreadcrumbs"]),

    goToLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
  font-size: 1.8rem;
  line-height: 2.4rem;
  @media screen and (max-width: $xs) {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}

.news {
  display: flex;

  &__header {
    &-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 58.4rem;
      z-index: 0;
      background-size: auto 100%;
      background-position: center;
      background-repeat: no-repeat;
      @media screen and (max-width: $xs) {
        height: 33.5rem;
      }

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($color: $lightBlack, $alpha: 0.45);
        z-index: 1;
      }
      &::after {
        content: "";
        bottom: -1px;
        position: absolute;
        width: 100%;
        height: 50%;
        background: linear-gradient(
          0deg,
          rgba($color: $lightBlack, $alpha: 1) 0%,
          rgba($color: $lightBlack, $alpha: 0) 100%
        );
        z-index: 1;
      }
    }
  }

  &__wrapper {
    position: relative;
    min-height: 100vh;
    min-width: 100vw;
  }

  &__main-container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40rem;
    padding: 0rem 10.5rem 35rem;
    z-index: 2;
    @media screen and (max-width: $md) {
      margin-top: 24.5rem;
    }
    @media screen and (max-width: $xs) {
      padding: 0rem 2rem 24rem;
    }
  }

  &__details-box {
    display: flex;
    flex-direction: column;
    width: 60%;
    @media screen and (max-width: $md) {
      width: 100%;
    }

    &_tags {
      display: flex;
      margin: 0 0 15px -10px;
    }

    &_title {
      font-size: 4rem;
      line-height: 5rem;
      @media screen and (max-width: $xs) {
        font-size: 3.5rem;
        line-height: 4.3rem;
      }
    }

    &_data {
      font-size: 1.6rem;
      line-height: 2.5rem;
      margin: 1rem 0rem 2.2rem;
      text-transform: uppercase;
      @media screen and (max-width: $xs) {
        font-size: 1.4rem;
        margin: 5.4rem 0rem 2.2rem;
      }
    }

    .button {
      margin-top: 4.2rem;
      min-width: 180px;
    }
  }
}
</style>
